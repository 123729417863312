// @flow
import React from 'react';
import type { Node, } from 'react';
import { useFela, } from 'react-fela';
import List from '../List/List';
import ListView from '../ListView/NewListView';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';

import AdSlot from '../AdManager/AdSlot';
import CatchupListElement from '../CatchupListElement/CatchupListElement';
import { isCountdown, isIframe, } from '../../utils/validateType';

import type { ListDataType, } from '../../flowTypes/ListDataType';
import type { AdSlotType, } from '../../flowTypes/AdSlotType';
import type { CountdownType, } from '../../flowTypes/CountdownType';
import type { IframeType, } from '../../flowTypes/IframeType';

type Props = {
  slotA: ListDataType[],
  slotB: AdSlotType[],
  slotC: any[],
  mainBlockComponents: Array<?CountdownType | ?IframeType>,
};

export default function MainBlock({ slotA, slotB, slotC, mainBlockComponents, }: Props): Node {
  const getComponent = useGetComponent();
  const { css, } = useFela();

  const isTopNewsElement = slotC && slotC.length > 0 && slotC[0].inputTemplate === 'CatchupList';

  const countdownIndex = mainBlockComponents.findIndex(isCountdown);

  const slotAView = slotA && slotA.length > 0 && slotA[0].view;

  const isWideMain = [ 'Conrad', ].includes(slotAView);
  const isBrannigan = [ 'Brannigan', 'Brannigan2', 'BranniganDark', ].includes(slotAView);
  const isBallet = [ 'Hermes', ].includes(slotAView);
  const isRoberto = [ 'Roberto', ].includes(slotAView);
  const supportsIframe = isRoberto;

  // $FlowFixMe
  // TODO: add this line when we supports iframe
  // const iframes: ?IframeType[] = supportsIframe ? mainBlockComponents.filter(isIframe) : null;

  // $FlowFixMe
  const countdownObj: ?CountdownType = isCountdown(mainBlockComponents[countdownIndex])
    ? mainBlockComponents[countdownIndex]
    : null;

  const htmlElements = mainBlockComponents.filter(isAnHtmlElement)
    .map(htmlElement => ({
      htmlElement,
      // $FlowFixMe
      HtmlElement: getComponent(htmlElement.inputTemplate),
    }));
  const hasHtmlElements = htmlElements.length > 0;

  const colTemplate = [];
  if (!isWideMain && !isBallet && !isBrannigan && !isRoberto) {
    colTemplate.push({ until: 'l', value: '1fr', }, { from: 'l', value: '10fr 4fr 5fr', });
  }
  if (isBallet) {
    colTemplate.push({ until: 'l', value: '1fr', }, { from: 'l', value: '3fr 2fr', });
  }
  if (isBrannigan || isRoberto) {
    colTemplate.push({ until: 'l', value: '1fr', }, { from: 'l', value: '1fr 1fr', });
  }
  return (
    <ListView
      colTemplate={colTemplate}
      outerBackgroundColor={isBallet ? [ 'neutral', '-4', ] : undefined}
      colGap={isWideMain ? '0' : [ { until: 'l', value: '0', }, { from: 'l', value: '4rem', }, ]}
      rowGap={[ { from: 's', until: 'l', value: isBallet ? '0' : '4rem', }, { from: 'l', value: '4rem', }, ]}
      gridGap={null}
      innerBackgroundColor={
        supportsIframe
          ? 'white'
          : [
            { until: 's', value: isBallet ? 'white' : [ 'neutral', '-7', ], },
            { from: 's', value: isBallet ? [ 'neutral', '-7', ] : 'white', },
          ]}
      padding={[ { until: 's', value: '0 2rem', }, { from: 's', value: isBallet ? '4rem 4rem 4rem 4rem' : '2rem 4rem 4rem 4rem', }, ]}
      marginTop={0}
      attrs={{
        'data-test': 'mainBlockHDC',
        'data-main': 'mainBlock',
      }}
    >
      {/* <List */}
      {slotA && slotA.length > 0 && (
      <List
        {...slotA[0]}
        viewProps={{
          countdownObj,
        }}
      />
      )}
      {isBallet || isBrannigan || !slotB?.length ? null : (
        <AdSlot
          {...slotB[0]}
          wrapperMiscStyles={{
            order: [ { from: 's', value: '3', }, { from: 's', until: 'l', value: 5, }, ],
            alignSelf: [ { from: 'l', value: isWideMain ? 'auto' : 'center', }, ],
            display: [ { until: 'l', value: 'none', }, ],
            gridColumnEnd: isRoberto
              ? [ { until: 'l', value: 'span 3', }, { from: 'l', until: 'xl', value: 'span 1', }, { from: 'xl', value: 'span 3', }, ]
              : (isBallet ? 'span 3' : [ { from: 's', until: 'xl', value: 'span 3', }, ]),
            ...(isBallet ? { backgroundColor: [ { from: 'l', value: 'white', }, ], } : {}),
          }}
        />
      )}
      {hasHtmlElements ? (
        <div className={css({ gridColumn: '1/-1', })}>
          {htmlElements.map(({ htmlElement, HtmlElement, }) => (
            <HtmlElement
              // $FlowFixMe
              key={htmlElement.contentId}
              {...htmlElement}
            />
          ))}
        </div>
      ) : null}
      {isBrannigan || !slotC?.length ? null : isTopNewsElement ? <CatchupListElement {...slotC[0]} /> : slotC ? <List {...slotC[0]} viewProps={{ isWideMain, }} /> : null }
    </ListView>
  );
}

function isAnHtmlElement(candidate) {
  return candidate
    ? candidate.inputTemplate === 'HtmlElement' || candidate.inputTemplate === 'InteractiveRichTextElement'
    : false;
}
